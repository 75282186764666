import {useState } from 'react';
import css from './Header.module.css'
import {Link} from 'react-scroll'
import logo from './img/logo.png'
import motion from 'framer-motion'
import React, { useContext } from 'react';
import { MyContext } from '../MyContextProvider/MyContextProvider'


const Header = () => {
    const { modal, setModal } = useContext(MyContext);// Глобальная переменная для отображения модально окна
    
    return (
<>
<section id='header'  className={css.container}>
<div className={css.imgContainer}>
        <Link spy={true} smooth={true} offset={50} duration={1500} to="hero" className={css.imgLink}><img src={logo} className={css.logo} id='headerLogo'/></Link>
</div>
<div className={css.buttonButger} onClick={()=> setModal(true)}>|||</div>
<div className={css.linkContainer}>

    <nav className={css.nav}>
        <Link spy={true} smooth={true} offset={-250} duration={1500} to="heroDesc" className={css.link}>О нас</Link>
        <Link spy={true} smooth={true} offset={-150} duration={1500} to="work" className={css.link}>Roadmap</Link>
        <Link spy={true} smooth={true} offset={-80} duration={1500} to="primer" className={`${css.link} ${css.nm}`}>Примеры работ</Link>
        <Link spy={true} smooth={true} offset={50} duration={1500} to="complects" className={css.link}>Комплекты </Link>
        <Link spy={true} smooth={true} offset={50} duration={1500} to="contacts" className={css.link}>Контакты</Link>
        <Link spy={true} smooth={true} offset={50} duration={1500}   className={`${css.link} ${css.nm}`}>+7 <span>499</span> 714-81-64</Link>
    </nav>
</div>
</section>
</>
    );
}
 
export default Header;
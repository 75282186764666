import css from './Burger.module.css'
import {Link} from 'react-scroll'
import React, { useContext } from 'react';
import { MyContext } from '../MyContextProvider/MyContextProvider'
import pmg from './img/pmg.png'
import {motion} from 'framer-motion'

const Burger = () => {
    const { modal, setModal } = useContext(MyContext);// Глобальная переменная для отображения модально окна
    return ( 
<>
{modal&&
    <motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:.3}} className={css.container}>
  <nav className={css.nav}>
        <Link spy={true} smooth={true} offset={-250} duration={1500} to="heroDesc" className={css.link} onClick={()=>setModal(false)}>О нас</Link>
        <Link spy={true} smooth={true} offset={-150} duration={1500} to="work" className={css.link} onClick={()=>setModal(false)} >Roadmap</Link>
        <Link spy={true} smooth={true} offset={-80} duration={1500} to="primer" className={`${css.link} ${css.nm}`} onClick={()=>setModal(false)}>Примеры работ</Link>
        <Link spy={true} smooth={true} offset={50} duration={1500} to="complects" className={css.link} onClick={()=>setModal(false)}>Комплекты</Link>
        <Link spy={true} smooth={true} offset={50} duration={1500} to="contacts" className={css.link} onClick={()=>setModal(false)}>Контакты</Link>
        <a className={css.link} href="tel:+74997148164" onClick={()=>setModal(false)}>+7 (499) 714-81-64</a>
    </nav>
    <div className={css.x} onClick={()=>setModal(false)}><img className={css.img} src={pmg}/></div>

  </motion.div>  
}

</>
     );
}
 
export default Burger;
import css from './About.module.css'
import group from './img/group.png'
import group1 from './img/group1.png'
import substrat from './img/Subtract.png'


const About = () => {
    return (
<>
<section className={css.container} id='about'> 
   <div className={css.nestedContainer}>
        <div className={css.textContainer} id='aboutText1'>
            <p className={css.description}>
            На сегодняшний день наши производственные мощности позволяют выпускать <span>более 104 млн.шт.</span> 
            контурной ячейковой упаковки (блистеров) в год.
            </p>
        </div>
        <div className={css.imgContainer}>
            <img src={group} className={css.img} id='aboutImg1'/>
        </div>
    </div> 
    <div className={css.nestedContainer}>
        <div className={css.imgContainer1} id='aboutImg2'>
            <img src={group1} className={css.img} />
        </div>
        <div className={css.textContainer1}>
            <p className={css.description1} id='aboutText2'>
            Мы используем материалы <span>ПВХ, ПЭТ, ПП, ПС</span> под флаконы и ампулы; 
            блистеры <span>глубокой формовки</span> для фармацевтики, 
            косметологии, пищевых и промышленных предприятий.
            </p>
        </div>
    </div> 
    {/* <img src={substrat} className={css.substrat}/>    */}
</section>
</>
    );
}
 
export default About;
import './App.css';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Gsap from './components/Gsap';
import { useLayoutEffect } from 'react';
import About from './components/About/About';
import Work from './components/Work/Work';
import Footer from './components/Footer/Footer';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import Primer from './components/Primer/Primer';
import Burger from './components/Burger/Burger';
import { MyContextProvider } from './components/MyContextProvider/MyContextProvider';
import GRDB from './components/GRDB/GRDB';
gsap.registerPlugin(ScrollTrigger);





function App() {
  useLayoutEffect(()=>{
    if (window.screen.width>window.screen.height){
      Gsap()
    }else{
      gsap.fromTo('#header',{background:'rgba(23, 23, 23,0)'},{
        background:'rgba(23, 23, 23,1)',
        scrollTrigger:{
            trigger:'#hero',
            start: "40% 70%",
            end:'20% top',
            scrub:true
        }
    })
    }
    
  })
  
  return (
<div className='App'>
<MyContextProvider>
<Burger/>
<Header/>
<Hero/>
<About/>
<Work/>
<Primer/>
<GRDB/>

<Footer/> 
</MyContextProvider>
 

</div>


  );
}

export default App;

import css from './GRDB.module.css'
import img from './img/img.png'
import {motion} from 'framer-motion'


const GRDB = () => {
  return (
<section className={css.container} id='complects'>
    <motion.h2 initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.header}>ГОТОВЫЕ РЕШЕНИЯ ДЛЯ БИЗНЕСА</motion.h2>
    <p className={css.description}>Также для наших заказчиков мы предлагаем готовые комплекты для вашего производства будь то ручное производство сувенирной продукции или огромное производственное предприятие.Наши специалисты подберут комплект соответствующий вашим запросам.
Мощности нашего производства позволяют нам предоставлять своим заказчикам комплекты в любом количестве, например, вы можете заказать у нас как небольшое количетсво комплектов, так и большие партии.</p>
    <img className={css.img}  src={img}/>
    <span className={css.description2}>Заполните форму обратной свзяи и наш специалист свяжется с вами и ответит на все интересующие вопросы!</span>
</section>
  )
}

export default GRDB
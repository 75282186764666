import css from './Work.module.css'
import png1 from './img/1.png'
import png2 from './img/2.png'
import png3 from './img/3.png'
import png4 from './img/4.png'
import png5 from './img/5.png'
import png6 from './img/6.png'
import png7 from './img/7.png'
import png8 from './img/8.png'
import png9 from './img/9.png'
import png10 from './img/10.png'
import substrat1 from './img/subtract1.png'
import substrat2 from './img/substrat2.png'
import {motion} from 'framer-motion'
import vector from './img/vector.png'
import gsap from 'gsap'

const lst =[{
    'id':'card1',
    'img': png1,
    'num':'1',
    'header': 'Определение целей и требований',
    'description':'Определяем для чего и для каких задач нужна блистерная упаковка заказчику , что он хочет в нее упаковать, уточняем детали, подбирая оптимальные варианты для заказчика'
},{
    'id':'card2',
    'img': png2,
    'num':'2',
    'header': 'Согласование чертежа с заказчиком',
    'description':'Согласуем с заказчиком все этапы производства: от согласования индивидуального или типового чертежа до этапа отгрузки'
},{
    'id':'card3',
    'img': png3,
    'num':'3',
    'header': 'Закупка материалов и оснащение склада',
    'description':'Закупка материалов для создания блистерной упаковки, а именно пленку ПВХ, ПЭТ, ПП, ПС'
},{
    'id':'card4',
    'img': png4,
    'num':'4',
    'header': 'Оценка качества',
    'description':'Оценка качества материалов которые будут использоваться для создания блистерной упаковки, в целом, от качества материалов зависит результат работы'
},{
    'id':'card5',
    'img': png5,
    'num':'5',
    'header': 'Сортировка и доставка материалов',
    'description':'Передача материалов в рабочую зону для дальнейшего производства товара.'
},{
    'id':'card6',
    'img': png6,
    'num':'6',
    'header': 'Разработка формы и инструментов',
    'description':'Передача чертежа производству, настройка оборудования и создание формы блистерной упаковки согласно чертежу, включая формы для литья, штампы и пресс-формы.'
},{
    'id':'card7',
    'img': png7,
    'num':'7',
    'header': 'Изготовление блистера',
    'description':'Используя материалы и инструменты, создаем блистер, формируя его согласно дизайну и размерам'
},{
    'id':'card8',
    'img': png8,
    'num':'8',
    'header': 'Этап упаковки продукта',
    'description':'Упаковка готовой продукции, нанесение соответствующей маркировки и передача на склад'
},{
    'id':'card9',
    'img': png9,
    'num':'9',
    'header': 'Погрузка',
    'description':'Подготовка готовой продукции к дальнейшей транспортировке на склад заказчика.'
},{
    'id':'card10',
    'img': png10,
    'num':'10',
    'header': 'Завершение сделки',
    'description':'Передача товара и обратная связь с заказчиком для обсуждения дальнейшего взаимодействия'
}]

const Work = () => {
    const width = window.screen.width
    const height = window.screen.height
    return (
<>
<section className={css.container} id='work'>
        <div className={css.vectorMask} id='vectorMask'></div>
        <img src={vector} className={css.vector}/>
        <h2 className={css.workHeader} id='workHeader'>Этапы взаимодействия с нами</h2>
        {lst.map((item,index)=>(
            <div key={index} className={`${css.card} ${index === 1 || index === 4 || index === 9 ? css.specialCard : ''}`}  id={item.id}     style={{
      ...(index % 2 === 0
        ? { marginLeft: width > height ? '20vw' : '0' }
        : { marginRight: width > height ? '20vw' : '0' }),
        }}>
                <div className={css.imgContainer}>
                    <img src={item.img} className={css.img}/>
                </div>
                <div className={css.textContainer}>
                    <h2 className={css.header}><span id={`${item.id}n`}>{item.num}</span>{item.header}</h2>
                    <motion.p initial={{opacity:0}} whileInView={{opacity:.8}} transition={{duration:1}} className={css.description}>
                        {item.description}
                    </motion.p>
                </div>
                
            </div>
                ))}
                
                {/* <img src={substrat1} className={css.substrat1} />
                <img src={substrat2} className={css.substrat2} />
                <img src={substrat1} className={css.substrat3} /> */}
</section>
</>
    );
}
 
export default Work;

import { useState } from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import css from './Primer.module.css'
import {motion} from 'framer-motion'



import img2 from './img/2.png'
import img3 from './img/3.png'
import img4 from './img/4.png'
import img5 from './img/5.png'
import img6 from './img/6.png'
import img7 from './img/7.png'


const images = [img2,img3,img4,img5,img6,img7];

function Primer() {
  const width = window.screen.width
  const height = window.screen.height
  const NextArrow = ({ onClick }) => {
    return (
      <div className={`${css.arrow} ${css.next}`} onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className={`${css.arrow} ${css.prev}`} onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: width>height?3:1,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
    return (
      <div className={css.container} id="primer">
      <motion.h2 initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.header}>Примеры наших работ</motion.h2>
      <div className={css.slider}>
        <Slider {...settings}>
          {images.map((img, idx) => (
            <div key={{idx}} className={idx === imageIndex ? `${css.slide} ${css.activeSlide}` : `${css.slide}`}>
              <img src={img} alt={img} />
            </div>
          ))}
        </Slider>
  
      </div>
      <div className={css.buttonContainer}>
            <a className={css.button} href='./Карточка организации ФАРМПРОФИПАК от 18.10.2023.pdf'  download>
            Скачать карточку компании
            </a>
            <a className={css.button} href='./Презентация ФПП.pdf'  download>
            Скачать презентацию
            </a>
  
        </div>
      </div>
  
    );
}

export default Primer;

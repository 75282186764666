import css from './Hero.module.css'

import logo from './img/logo.png'
import subtract from './img/subtract.png'
import { useEffect, useState } from 'react'
import  { motion} from "framer-motion";
import { Link } from 'react-scroll';

const Hero = () => {
    const [coords, setCoords] = useState({x: 0, y: 0});

    const handleWindowMouseMove = event => {
        if(window.screenY<=100){
            setCoords({
                x: event.clientX,
                y: event.clientY,
              });
        }


      };
    useEffect(() => {

        window.addEventListener('mousemove',(event)=>{
            if(window.scrollY<=250){
                setCoords({
                    x: event.clientX/50,
                    y: event.clientY/50,
                  });
            }

        });

        }, []);
    return (
<>
<section id='hero' className={css.container}>
    <div className={css.heroContainer}>
        <div className={css.imgContainer}>
            <motion.img initial={{opacity:0}} whileInView={{opacity:.8}} transition={{duration:1}} src={logo} className={css.logo} style={{marginRight:`${coords.x}px`,marginTop:`${coords.y}px`}}/>

        </div>
        <div className={css.headerContainer} >
            <motion.h1 initial={{opacity:0}} whileInView={{opacity:.8}} transition={{duration:1}} className={css.header}>Российский производитель контурной ячейковой упаковки <span>с 2011 года</span></motion.h1>
        </div>
        {/* <img className={css.subtract} src={subtract}/> */}
    </div>
    <Link spy={true} smooth={true} offset={50} duration={1500} to='complects' className={css.link}>Готовые решения для вашего бизнеса</Link>
    <div className={css.description} id='heroDesc'>
        {window.screen.width>780
        ?
        <motion.p initial={{opacity:0}} whileInView={{opacity:.8}} transition={{duration:1}}  >
        Компания ООО «ФармПрофиПак» ведет свою историю с 2011 года. Все эти годы мы не просто работали, 
        а развивались сами в составе группы компаний и помогали развивать рынок, делая его эффективным. 
        Многолетний опыт и безупречная репутация позволили нам нарастить мощности производства и стать 
        самостоятельной компанией.
        </motion.p>
        :
        <p  >
        Компания ООО «ФармПрофиПак» ведет свою историю с 2011 года. Все эти годы мы не просто работали, 
        а развивались сами в составе группы компаний и помогали развивать рынок, делая его эффективным. 
        Многолетний опыт и безупречная репутация позволили нам нарастить мощности производства и стать 
        самостоятельной компанией.
        </p>
        }

    </div>
</section>
</>
    );
}
 
export default Hero;
import { useState } from 'react';
import css from './Footer.module.css'
import {motion} from 'framer-motion'
import InputMask from 'react-input-mask';
import { useRef } from "react";
import axios from 'axios';
import gsap from 'gsap';
import numberValidator from "../utils/numberValidator";


// Данные для отправки в телеграмм
const token = '6779032013:AAGSGDsPqf_SkMoKrvwVJsVfpX_df1aZBW4'
const chatId = '-4045547765'
const URI_API = `https://api.telegram.org/bot${ token }/sendMessage`




const Footer = () => {
 // Состояние для бордера Номера
    const [formValid, setFormValid] = useState('none') // Состоаяние для появления фона галочки
    const [formInvalid, setFormInvalid] = useState('none') // Состоаяние для появления тревожной надписи
    const formInvalidRef = useRef() //Реф для анимирования тревожной надписи
    const formValidRef = useRef()  //Реф для анимировния изрбражения галочки
    const formValidFonRef = useRef() // Реф для анимирования фона галочки
    const [name , setName] = useState('') // Состояние имени
    const [post , setPost] = useState('') // Состояние имени
    const [number, setNumber] = useState('') // Состояние номера
    const [inputName,setInputName ] = useState(' 1px solid #375875') //Состояние для бордрера имени
    const [inputNumber,setInputNumber]= useState('1px solid #375875') // Состояние для бордера Номера
    const [inputArea,setInputArea]= useState('1px solid #375875') // Состояние для бордера Номера
    const buttonRef = useRef()



    let message = `<b>Заявка с сайта!</b>\n`
    message += `<b>Отправитель: ${name}</b>\n`
    message += `<b>Номер: ${number}</b>\n`
    message += `<b>Запрос: ${post}</b>\n`


    const handleEstimateClick = (event) => {
        event.preventDefault(); // Сброс дефолтного поведения кнопки

        console.log(buttonRef)
        // Проверка валидности формы
        if(numberValidator(number ) && name.length>0){//Если форма валидна
            try {
                axios.post(URI_API, {
                    chat_id: chatId,
                    parse_mode: 'html',
                    text: message
                });
                    // Дополнительный код, который нужно выполнить после успешного запроса
                } catch (error) {
                    // Обработка ошибок, которые могут возникнуть во время запроса
                    console.error('Ошибка при выполнении запроса:', error);
                    // Дополнительный код для обработки ошибок
                }
            buttonRef.current.textContent = 'Заявка отправлена';
            gsap.to(buttonRef.current,{background:'rgb(29, 106, 164)'})
            // Сброс полей
            setName('')
            setNumber('')
            setPost('')
            // gsap.to(buttonRef.current,{})
            // Скрытие модального окна
            // Появление галочки
            setFormValid('flex')
            // Убираю тревожные бордеры
            setInputNumber('1px solid #375875')
            setInputName('1px solid #375875')
            //Убираю тревожное оповещение надпись
            setFormInvalid('none')
            //Анимирование галочки
            gsap.from(formValidRef.current,{opacity:0,duration:4})
            gsap.to(formValidRef.current,{opacity:0,duration:3,delay:1})
            gsap.to(formValidFonRef.current,{opacity:0,duration:3,delay:1})
            gsap.to(formValidFonRef.current,{opacity:1,delay:15})
            gsap.to(formValidRef.current,{opacity:1,delay:15})
        }
        if(!numberValidator(number)&& name.length===0){ // Если неправильно введено все
            //Установка тревожных бордеров
            setInputNumber('1px solid red')
            setInputName('1px solid red')
            //Появление тревожной надписи
            setFormInvalid('block')
            //Анимирование появления тревожной надписи
            gsap.from(formInvalidRef.current,{opacity:0,duration:2})
        }
        if(numberValidator(number )&& name.length===0){//Если неправильно введено Имя
            //Установка обычного бордера на номер
            setInputNumber('1px solid #c5c5c5')
            //Установка тревожного борлера на Имя 
            setInputName('1px solid red')
            //Появление тревожной надписи
            setFormInvalid('block')
            //Анимирование появления тревожной надписи
            gsap.from(formInvalidRef.current,{opacity:0,duration:2})
        }
        if(!numberValidator(number )&& name.length>0){
            //Установка тревожного бордера на номер
            setInputNumber('1px solid red')
            //Устанвока обысного бордера на имя
            setInputName('1px solid #c5c5c5')
            //Появление тревожной надписи
            setFormInvalid('block')
            //Анимирование появления тревожной надписи
            gsap.from(formInvalidRef.current,{opacity:0,duration:2})
        }
    }


    return (  
<>

    <section className={css.container} id='contacts'>
        <a className='webHub' href='https://web-hub.pro'>Создание сайтов под ключ, лендинг, интернет магазин, корпоративный сайт</a>
        <motion.h2 initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.header} id='footerHeader'>Наши контакты</motion.h2>
        <div className={css.nestedContainer}>
            <div className={css.contactContainer}>
            <motion.h3 initial={{opacity:0}} whileInView={{opacity:.9}} transition={{duration:1}} className={css.name1}>Коммерческий директор</motion.h3>
            <motion.h3 initial={{opacity:0}} whileInView={{opacity:.9}} transition={{duration:1}} className={css.name}>Кирпичников Максим Артемьевич</motion.h3>
            <motion.a  initial={{opacity:0}} whileInView={{opacity:.8}} transition={{duration:1}} className={css.mail} href="mailto:yuriy.M@PharmPP.ru">maxim.k@pharmpp.ru</motion.a>
            <motion.a  initial={{opacity:0}} whileInView={{opacity:.7}} transition={{duration:1}} className={css.number} href="tel:+9032657447">+7 (903) 265-74-47</motion.a>
            <motion.h3 initial={{opacity:0}} whileInView={{opacity:.9}} transition={{duration:1}} className={css.name1}>Адрес производства:</motion.h3>
            <motion.h3 initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.adress}>Московская область,г.о. Пушкинский, 
                                        г. Красноармейск, ул. Академика Янгеля,
                                        д.23, стр.9</motion.h3>
            
            </div>
            <div className={css.formContainer}>
            <input value={name} style={{border:inputName}}  placeholder='Ваше Имя' onChange={(e)=>{setName(e.target.value)}} className={css.ModalInputName} />
                                       <InputMask
                                        style={{border:inputName}}
                                           value={number}
                                           mask="+7(999)999-99-99"
                                           placeholder='Ваш телефон'
                                           className={css.ModalInputPhone}
                                           onChange={(e)=>{setNumber(e.target.value)}}
                                       />
                                        <textarea value={post} style={{border:inputArea}}  placeholder='Введите ваш запрос' onChange={(e)=>{setPost(e.target.value)}} className={css.modalinputArea} />
            <div  className={css.button} ref={buttonRef} onClick={handleEstimateClick}>Отправить заявку</div>

            </div>
        </div>


    </section>
</>
    );
}
 
export default Footer;
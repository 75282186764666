import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);


const Gsap = () => {
    var tl = gsap.timeline({repeat: 2, repeatDelay: 1});
    // Анимация header
    gsap.fromTo('#header',{background:'rgba(23, 23, 23,0)'},{
        background:'rgba(23, 23, 23,1)',
        scrollTrigger:{
            trigger:'#hero',
            start: "40% 70%",
            end:'20% top',
            scrub:true
        }
    })
    gsap.fromTo('#headerLogo',{opacity:0},{
        opacity:.7,

        scrollTrigger:{
            trigger:'#hero',
            start: "70% 70%",
            end:'120% top',
            scrub:true,
        }
    })
    // About
    gsap.fromTo('#aboutText1',{opacity:0,x:-100},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#workHeader',
            start: "-1270% bottom",
            end:'-700% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#aboutImg1',{opacity:0,x:100},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#workHeader',
            start: "-1270% bottom",
            end:'-700% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#aboutText2',{opacity:0,x:100},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#workHeader',
            start: "-770% bottom",
            end:'-300% 70%',
            scrub:true,

        }
    })
    gsap.fromTo('#aboutImg2',{opacity:0,x:-100},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#workHeader',
            start: "-770% bottom",
            end:'-300% 70%',
            scrub:true,
        }
    })
    //work
    gsap.fromTo('#workHeader',{opacity:0,scale:.9},{
        opacity:1,
        scale:1,
        scrollTrigger:{
            trigger:'#primer',
            start: "-400% bottom",
            end:'-360% 70%',
            scrub:true
        }
    })
    gsap.fromTo('#card1',{opacity:0,x:200},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#card2',
            start: "-80% bottom",
            end:'-50% 70%',
            scrub:true,
        }
    })
    gsap.to('#vectorMask',{height:'100%'})
    tl.fromTo('#vectorMask',{height:'100%'},{
        height:'84%',
        scrollTrigger:{
            trigger:'#card2',
            start: "-10% bottom",
            end:'30% 70%',
            scrub:true,
        }
    })
    tl.fromTo('#vectorMask',{height:'84%'},{
        height:'62%',
        scrollTrigger:{
            trigger:'#card4',
            start: "-10% bottom",
            end:'bottom 70%',
            scrub:true,
        }
    })
    tl.fromTo('#vectorMask',{height:'62%'},{
        height:'45%',
        scrollTrigger:{
            trigger:'#card6',
            start: "-10% bottom",
            end:'bottom 70%',
            scrub:true,
        }
    })
    tl.fromTo('#vectorMask',{height:'45%'},{
        height:'25%',
        scrollTrigger:{
            trigger:'#card8',
            start: "-10% bottom",
            end:'bottom 70%',
            scrub:true,
        }
    })
    tl.fromTo('#vectorMask',{height:'25%'},{
        height:'5%',
        scrollTrigger:{
            trigger:'#card10',
            start: "-10% bottom",
            end:'bottom 70%',
            scrub:true,
        }
    })


    gsap.fromTo('#card1n',{opacity:0},{
        opacity:1,
        scrollTrigger:{
            trigger:'#card2',
            start: "30% bottom",
            end:'20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card2',{opacity:0,x:-200},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#card3',
            start: "-80% bottom",
            end:'-50% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card2n',{opacity:0},{
        opacity:1,
        scrollTrigger:{
            trigger:'#card3',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card3',{opacity:0,x:200},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#card4',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card3n',{opacity:0},{
        opacity:1,
        scrollTrigger:{
            trigger:'#card4',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card4',{opacity:0,x:-200},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#card5',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card4n',{opacity:0},{
        opacity:1,
        scrollTrigger:{
            trigger:'#card5',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card5',{opacity:0,x:200},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#card6',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card5n',{opacity:0},{
        opacity:1,
        scrollTrigger:{
            trigger:'#card6',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card6',{opacity:0,x:-200},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#card7',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card6n',{opacity:0},{
        opacity:1,
        scrollTrigger:{
            trigger:'#card7',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card7',{opacity:0,x:200},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#card8',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card7n',{opacity:0},{
        opacity:1,
        scrollTrigger:{
            trigger:'#card8',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card8',{opacity:0,x:-200},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#card9',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card8n',{opacity:0},{
        opacity:1,
        scrollTrigger:{
            trigger:'#card9',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card9',{opacity:0,x:200},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#card10',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card9n',{opacity:0},{
        opacity:1,
        scrollTrigger:{
            trigger:'#card10',
            start: "-30% bottom",
            end:'-20% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card10',{opacity:0,x:-200},{
        opacity:1,
        x:0,
        scrollTrigger:{
            trigger:'#primer',
            start: "-30% bottom",
            end:'-10% 70%',
            scrub:true,
        }
    })
    gsap.fromTo('#card10n',{opacity:0},{
        opacity:1,
        scrollTrigger:{
            trigger:'#primer',
            start: "10% bottom",
            end:'20% 70%',
            scrub:true,
        }
    })
    return null;
}
 
export default Gsap;
